import { Delete } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import PageContainer from "../Statics/PageContainer";
import { PopulatedClient } from "../Types/Client";
import ClientContext from "./ClientContext";
import ClientForm from "./ClientForm";
import ConnectedClients from "./ConnectedClients";
import ConnectedUsers from "./ConnectedUsers";
import useOnClientDelete from "./onClientDelete";
import PublicKey from "./PublicKey";

export default function ClientPage() {
  const navigate = useNavigate();
  const [client, setClient] = useState<PopulatedClient>();
  const { clientid } = useParams();
  const getClient = useCallback(async () => {
    if (!clientid) return;
    try {
      const clientData = await api.getClient(clientid!);
      setClient(clientData);
    } catch (error) {
      const { message } = error as Error;
      toast(message, { type: "error" });
    }
  }, [clientid]);
  const [fetch, loading] = useAsyncRequest(getClient);
  const onDelete = useOnClientDelete(
    client ? client.id : "",
    client ? client.name : "",
    async () => navigate("/")
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!loading && !client) {
    return <div>Not Found</div>;
  }

  return (
    <PageContainer>
      <ClientContext.Provider value={{ current: client, fetch: getClient }}>
        {loading || !client ? (
          <div>Loading...</div>
        ) : (
          <>
            <Typography variant="h3" component="h3">
              {client!.name}
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              style={{ fontWeight: "lighter" }}
            >
              ID: {client!.id}
            </Typography>
            <Button
              onClick={onDelete}
              endIcon={<Delete />}
              variant="contained"
              color="error"
              className="client__delete"
            >
              Delete Client
            </Button>
            <PublicKey />
            <ClientForm />
            <ConnectedUsers />
            <ConnectedClients />
          </>
        )}
      </ClientContext.Provider>
    </PageContainer>
  );
}
