import { Delete, Key } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { memo, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Client from "../Types/Client";
import onClientDelete from "./onClientDelete";

const ClientCard: React.FC<Client & { refresh: () => Promise<void> }> = memo(
  ({ name, id, public_key, refresh }) => {
    const onClickKey = useCallback(
      async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        await navigator.clipboard.writeText(public_key);
        toast("Public Key copied to clipboard", { type: "success" });
      },
      [public_key]
    );

    const onDelete = onClientDelete(id, name, refresh);
    return (
      <NavLink to={`/client/${id}`} style={{ textDecoration: "none" }}>
        <Box className="client__card" sx={{ boxShadow: 1, borderRadius: 1 }}>
          <Typography component="div" className="client__cardname">
            {name}
          </Typography>
          <div className="client__cardactions">
            <IconButton
              title="Copy Public Key"
              className="client__cardaction"
              onClick={onClickKey}
            >
              <Key fontSize="large" />
            </IconButton>
            <IconButton
              title="Delete Client"
              className="client__cardaction client__cardaction--delete"
              onClick={onDelete}
              color="error"
            >
              <Delete fontSize="large" />
            </IconButton>
          </div>
        </Box>
      </NavLink>
    );
  }
);

export default ClientCard;
