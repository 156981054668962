import React from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Typography, Box } from "@mui/material";
import useOnAccountDelete from "./onAccountDelete";
import { useNavigate } from "react-router";

function DeleteAccount() {
    const navigate = useNavigate();
    const onDelete = useOnAccountDelete(
        async () => {
            navigate("/");
            window.location.reload();
        }
      );
  return (
    <Box className="container__card" sx={{ boxShadow: 2 }}>
        <Typography variant="h3" component="div" className="container__header">
            Delete Account
        </Typography>
        <Button
            variant="contained"
            color="warning"
            className="client__card client__card--new"
            sx={{ boxShadow: 1, borderRadius: 1, background: "warning" }}
            onClick={onDelete}
        >
            <DeleteIcon fontSize="large" /> Delete
        </Button>
    </Box>
  )
}

export default DeleteAccount