const env = process.env.NODE_ENV;

const envVariables = {
  api_url: "REACT_APP_API_URL",
  gql_url: "REACT_APP_GQL_URL",
  client_id: "REACT_APP_CLIENT_ID",
  client_key: "REACT_APP_CLIENT_KEY",
};

const apiUrls = {
  localhost: "http://localhost:8100",
  "authserver.tldev.eu": "https://authserver.tldev.eu/api",
  "auth.teamliquid.com": "https://auth.teamliquid.com/api"
};

class Config {
  env: "production" | "development" | "test" = "development";
  constructor() {
    this.env = env;
  }

  getEnv(key: string) {
    const envVariable = Object(envVariables)[key];
    return envVariable ? process.env[envVariable] : undefined;
  }

  get(key: string) {
    return this.getEnv(key);
  }

  getAPIUrl() {
    const host = window.location.hostname;
    return Object(apiUrls)[host];
  }
}

export default new Config();
