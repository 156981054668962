import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { hasAllRoles, hasAnyRole } from "../State/selectors";

type RoleInclusivity = "all" | "any";

export interface AuthWrapperProps {
  inclusivity?: RoleInclusivity;
  roles: string[];
  render?: boolean;
}

const AuthWrapper: React.FC<AuthWrapperProps> = memo<AuthWrapperProps>(
  ({ inclusivity = "any", roles, children, render = true }) => {
    const hasRoles = useRecoilValue(
      inclusivity === "all" ? hasAllRoles(roles) : hasAnyRole(roles)
    );

    return hasRoles ? (
      <>{children}</>
    ) : //TODO: ADD ERROR PAGE
    render ? (
      <div>You do not have the privileges to view this page.</div>
    ) : (
      <></>
    );
  }
);

export default AuthWrapper;
