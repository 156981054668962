import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import api from "../Api/api";

// Used as a callback page when OAuth is used
// Redirects once the OAuth JWT Code has been taken from the URL params
export default function Auth() {
  const navigate = useNavigate();
  useEffect(() => {
    const params = window.location.search;
    if (window.opener) {
      // window.opener.postMessage(params);
      // window.close();
    } else {
      const urlParams = new URLSearchParams(params);

      api.onLogin(urlParams.get("token") ?? "");
      navigate("/");
    }
  });

  return <div>Loading...</div>;
}
