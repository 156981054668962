import React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import ClientPage from "./Clients/ClientPage";
import NewClient from "./Clients/NewClient";
import Auth from "./Pages/Auth";
import Home from "./Pages/Home";

export default function Routes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/client/:clientid" element={<ClientPage />} />
      <Route path="/client/new" element={<NewClient />} />
      {/* <Route path="/oauth/:oauth" element={<OAuthRedirect />} /> */}
      <Route path="/auth" element={<Auth />} />
    </ReactRoutes>
  );
}
