import { selectorFamily, selector } from "recoil";
import { userState } from "./atoms";

export const getUsername = selector({
  key: "username",
  get: ({ get }) => {
    const user = get(userState);
    if (!user) return "Anonymous";
    const socialKeys = Object.keys(user.socials);
    for (let i = 0; i < socialKeys.length; i++) {
      const key = socialKeys[i];
      const socialsArray = Object(user.socials)[key];
      for (let j = 0; j < socialsArray.length; j++) {
        const socialDetails = socialsArray[j];
        if (socialDetails.email) return socialDetails.email;
      }
    }
    if (user.socials && user.socials.default) {
      return user.socials.default.email ?? "Anonymous";
    }

    return "Anonymous";
  },
});

export const hasRole = selectorFamily<boolean, string>({
  key: "hasRole",
  get:
    (role) =>
    ({ get }) => {
      const user = get(userState);
      if (!user) return false;
      return user.roles.includes(role);
    },
});

export const hasAllRoles = selectorFamily<boolean, string[]>({
  key: "hasAllRoles",
  get:
    (roles) =>
    ({ get }) => {
      const user = get(userState);
      if (!user) return false;
      return roles.every((role) => user.roles.includes(role));
    },
});

export const hasAnyRole = selectorFamily<boolean, string[]>({
  key: "hasAllRoles",
  get:
    (roles) =>
    ({ get }) => {
      const user = get(userState);
      if (!user) return false;
      return roles.some((role) => user.roles.includes(role));
    },
});
