import { createContext } from "react";

export type LoginState =
  | "login"
  | "register"
  | "emailverify"
  | "registerverify";

export interface LoginContext {
  current: LoginState;
  set: (state: LoginState) => void;
}

const defaultContext: LoginContext = {
  current: "login",
  set: () => {},
};

export default createContext(defaultContext);
