import { AccountCircle, Home } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import api from "../Api/api";
import { userState } from "../State/atoms";
import { getUsername } from "../State/selectors";

export default function Nav() {
  const [user, setUser] = useRecoilState(userState);
  const username = useRecoilValue(getUsername);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = useCallback(async () => {
    try {
      await api.logout();
      setUser(undefined);
    } catch (error) {
      const { message } = error as Error;
      toast(message, { type: "error" });
    }
  }, [setUser]);

  return (
    <AppBar className="nav__container" position="static">
      <Toolbar className="nav__toolbar">
        <NavLink to="/" className="nav__link">
          <Typography variant="h3" component="div">
            <Home fontSize="large" />
          </Typography>
        </NavLink>
        {user && (
          <div className="nav__link nav__profile">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Typography component="div" className="nav__username">
                <AccountCircle /> {username}
              </Typography>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
