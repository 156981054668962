import { AccountCircle, ArrowBack } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import LoginContext from "../Login/LoginContext";

interface RegistrationData {
  email: string;
  password: string;
  repeatpassword: string;
}

function validateEmail(email: string): string | undefined {
  if (email.length === 0) return;

  var re = /\S+@\S+\.\S+/;
  return re.test(email) ? undefined : "Please provide a valid email";
}

function validatePassword(password: string): string | undefined {
  if (password.length === 0) return;

  if (password.length < 8 || password.length > 25)
    return "Password must be between 8 and 25 characters long";

  return;
}

function validateRepeatPassword(
  password: string,
  repeatpassword: string
): string | undefined {
  if (password.length === 0 || repeatpassword.length === 0) return;

  if (repeatpassword !== password) return "Passwords do not match";

  return;
}

const Register = React.memo(() => {
  const { set } = useContext(LoginContext);
  const [data, setData] = useState<RegistrationData>({
    email: "",
    password: "",
    repeatpassword: "",
  });
  const [errors, setErrors] = useState<(string | undefined)[]>([
    undefined,
    undefined,
    undefined,
  ]);
  const validate = useCallback(() => {
    const newErrors = [
      validateEmail(data.email),
      validatePassword(data.password),
      validateRepeatPassword(data.password, data.repeatpassword),
    ];
    setErrors(newErrors);
    return newErrors.every((err) => typeof err === "undefined");
  }, [data]);

  const submit = useCallback(async () => {
    if (!validate()) return;
    try {
      await api.register(data.email, data.password);
      set("registerverify");
    } catch (err) {
      const { message } = err as Error;
      toast(message, { type: "error" });
    }
  }, [data, set, validate]);
  const [onSubmit, loading] = useAsyncRequest(submit);

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setData({ ...data, [name]: value });
    },
    [data]
  );

  const onBlur = useCallback(
    (e: any) => {
      e.preventDefault();
      validate();
    },
    [validate]
  );

  return (
    <Container className="container__outercentered">
      <Box className="container__innercentered" sx={{ boxShadow: 2 }}>
        <div className="register__back" onClick={() => set("login")}>
          <ArrowBack /> Back
        </div>
        <div className="register__header">
          <AccountCircle /> Register
        </div>
        <form
          className="register__form register__form--full"
          onSubmit={onSubmit}
        >
          <TextField
            label="Email"
            variant="outlined"
            autoFocus
            type="email"
            className="register__input"
            required
            name="email"
            onChange={onChange}
            error={Boolean(errors[0])}
            helperText={errors[0]}
            onBlur={onBlur}
            value={data.email}
          />
          <TextField
            label="Password"
            variant="outlined"
            autoFocus
            type="password"
            className="register__input"
            required
            name="password"
            onChange={onChange}
            error={Boolean(errors[1])}
            helperText={errors[1]}
            onBlur={onBlur}
            value={data.password}
          />
          <TextField
            label="Repeat Password"
            variant="outlined"
            autoFocus
            type="password"
            className="register__input"
            required
            name="repeatpassword"
            onChange={onChange}
            error={Boolean(errors[2])}
            helperText={errors[2]}
            onBlur={onBlur}
            value={data.repeatpassword}
          />
          <LoadingButton
            variant="contained"
            className="register__submit"
            type="submit"
            loading={loading}
            disabled={
              errors.some((error) => typeof error !== "undefined") ||
              data.email.length === 0 ||
              data.password.length === 0 ||
              data.repeatpassword.length === 0
            }
          >
            Submit
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
});

export default Register;
