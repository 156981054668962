import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import { userState } from "../State/atoms";
import LoginContext from "./LoginContext";
import Verify from "./Verify";

interface DefaultLoginData {
  email: string;
  password?: string;
}

function validateEmail(email: string): string | undefined {
  if (email.length === 0) return;

  var re = /\S+@\S+\.\S+/;
  return re.test(email) ? undefined : "Please provide a valid email";
}

interface DefaultLoginProps {
  setParentState?: (state: string) => any;
}

const DefaultLogin = React.memo<DefaultLoginProps>(({ setParentState }) => {
  const { set } = useContext(LoginContext);
  const [state, setState] = useState<"email" | "password" | "verify">("email");
  const [data, setData] = useState<DefaultLoginData>({
    email: "",
  });
  const [errors, setErrors] = useState<(string | undefined)[]>([
    undefined,
    undefined,
  ]);
  const setUser = useSetRecoilState(userState);

  const submit = useCallback(async () => {
    switch (state) {
      case "email":
        try {
          await api.emailLogin(data.email);
          if (set) {
            set("emailverify");
          } else {
            setState("verify");
          }
        } catch (error) {
          throw error;
        }
        return;
      case "password":
        try {
          const { code } = await api.defaultLogin(data.email, data.password!);
          await api.onLogin(code);
          const user = await api.me();
          setUser(user);
        } catch (error) {
          const { message } = error as Error;
          toast(message, { type: "error" });
        }
    }
  }, [data, state, set, setUser]);
  const [onSubmit, loading] = useAsyncRequest(submit);

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setData({ ...data, [name]: value });
    },
    [data]
  );

  const validate = useCallback(() => {
    const newErrors = [validateEmail(data.email)];
    setErrors(newErrors);
    return newErrors.every((err) => typeof err === "undefined");
  }, [data]);

  const onBlur = useCallback(
    (e) => {
      e.preventDefault();
      validate();
    },
    [validate]
  );

  if (state === "verify") {
    return <Verify callback={async () => {}} />; //TODO: ADD CALLBACK TO VERIFY
  }

  return (
    <form
      className="register__form register__form--full"
      style={{ paddingBottom: "0px" }}
      onSubmit={onSubmit}
    >
      <TextField
        label="Email"
        variant="outlined"
        autoFocus
        type="email"
        className="register__input"
        required
        name="email"
        onChange={onChange}
        error={Boolean(errors[0])}
        helperText={errors[0]}
        onBlur={onBlur}
        value={data.email}
      />
      {state === "password" ? (
        <>
          <TextField
            label="Password"
            variant="outlined"
            autoFocus
            type="password"
            className="register__input"
            required
            name="password"
            onChange={onChange}
            onBlur={onBlur}
            value={data.password}
          />
          <div className="login__subtext" onClick={() => setState("email")}>
            Use Email only to login
          </div>
        </>
      ) : (
        <div className="login__subtext" onClick={() => setState("password")}>
          Use password to login
        </div>
      )}
      <LoadingButton
        variant="contained"
        className="register__submit"
        type="submit"
        loading={loading}
        disabled={
          errors.some((error) => Boolean(error)) ||
          data.email.length === 0 ||
          (state === "password" &&
            (!data.password || data.password!.length === 0))
        }
      >
        Login
      </LoadingButton>
      <div className="login__subtext">Forgot your password?</div>{" "}
      {/*TODO ADD FORGOT PASSWORD!*/}
      <Button
        variant="contained"
        className="register__submit"
        onClick={() => set("register")}
      >
        Register
      </Button>
    </form>
  );
});

export default DefaultLogin;
