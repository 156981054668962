import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TextField, Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import useAsyncRequest from "../Api/useAsyncRequest";

export interface VerifyProps {
  callback: (code: string) => Promise<any>;
  back?: () => void;
}

export default function Verify({ callback, back }: VerifyProps) {
  const [code, setCode] = useState<string>("");
  const verify = useCallback(async () => {
    await callback(code);
  }, [code, callback]);
  const [onSubmit, loading] = useAsyncRequest(verify);

  return (
    <Box className="container__innercentered" sx={{ boxShadow: 2 }}>
      {back && (
        <div className="register__back" onClick={back}>
          <ArrowBack /> Back
        </div>
      )}
      <div className="register__header">Verify Code</div>
      <form className="register__form register__form--full" onSubmit={onSubmit}>
        <div className="register__verify">
          A verification code has been sent to your Email address
        </div>
        <TextField
          label="Code"
          variant="outlined"
          autoFocus
          type="text"
          className="register__input"
          required
          name="code"
          onChange={({ target: { value } }) => setCode(value)}
          value={code}
        />
        <LoadingButton
          variant="contained"
          className="register__submit"
          type="submit"
          loading={loading}
          disabled={code.length === 0}
        >
          Verify
        </LoadingButton>
      </form>
    </Box>
  );
}
