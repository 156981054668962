export enum SocialType {
  Google = "google",
  Twitter = "twitter",
  Reddit = "reddit",
  Discord = "discord",
  Twitch = "twitch",
  Apple = "apple",
}

export default interface Social {
  type: SocialType;
  client_id: string;
  client_secret: string;
}
