import { useCallback, useState } from "react";
import { toast } from "react-toastify";

export default function useAsyncRequest<T>(
  cb: (...args: any) => Promise<T>
): [(e?: any) => Promise<T | undefined>, boolean] {
  const [loading, setLoading] = useState(false);

  const callback = useCallback(
    async (e) => {
      if (e) e.preventDefault();

      setLoading(true);
      let res;
      try {
        res = await cb();
        setLoading(false);
        return res;
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
      } finally {
        setLoading(false);
      }
    },
    [cb]
  );

  return [callback, loading];
}
