import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import api from "../Api/api";

export default function useOnAccountDelete(
  refresh: () => Promise<void>
) {
  const toastId = useRef<any>(null);
  return useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (toast.isActive(toastId.current)) return;
      toastId.current = toast(
        `Are you sure you want to delete your account? Click here to delete.`,
        {
          type: "error",
          autoClose: 15000,
          position: "top-center",
          onClick: async () => {
            await api.getClients().then(resp => {
              resp.forEach(async (client: any) => {
                await api.deleteClient(client.id);
              })
            })
            await api.deleteUserAccount();
            toast("User deleted! You will be redirected to the main page.", { type: "success" });
            refresh();
          },
        }
      );
    },
    [refresh]
  );
}
