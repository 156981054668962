import React from "react";
import { useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import LoginPage from "../Pages/LoginPage";
import { userState } from "../State/atoms";

const locationExceptions = ["/login", "/auth", "/oauth"];

const LoggedInWrapper = React.memo(({ children }) => {
  const user = useRecoilValue(userState);
  const { pathname } = useLocation();
  return user || locationExceptions.some((url) => pathname.includes(url)) ? (
    <>{children}</>
  ) : (
    <LoginPage />
  );
});

export default LoggedInWrapper;
