/* eslint-disable @typescript-eslint/no-unused-vars */
import { Login as LoginIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import DefaultLogin from "./DefaultLogin";
import OAuthLogin from "./OAuthLogin";

interface LoginProps {
  setParentState?: Dispatch<SetStateAction<string>>;
}

const Login = React.memo<LoginProps>(({ setParentState }) => {
  return (
    <Box className="container__innercentered " sx={{ boxShadow: 2 }}>
      <div className="register__header">
        <LoginIcon /> Login
      </div>
      <DefaultLogin />
      {/* <OAuthLogin /> */}
    </Box>
  );
});

export default Login;
