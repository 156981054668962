import { JWE, JWK } from "node-jose";

const clientId: String = "JA5AUsArT5F9QxuyCPpqVbAvOw7l25aSvScv7twf63IMIzUOZk";
const publicKey: String =
  "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsAIn/3K5Jv5EDXJ7+Qe6\nGL9cLGU0KyaQqCqi+G3W8VnQmd183+F5/vfXYTB16K0+P/E8ZwZuWPhix2Y+JSIo\nn9/f16L6VT/lqouxsoOBVyaAa6/jmWVJ1Pd/nwX8gEnxsZb+0I/veGV9gTLhhGv0\nVmGSyvEE3VBq5GTZ2erH9gjchJaDhFQHwkJq+x0O0wDSdKT2avNFcOlFn/8xSoE0\nkf9xBNNpNUGBuGrmy+cMf3gPLsnDvT7JRx3Fj5MiRkIT/112TmdFUHeYCzmz7/0l\nfoz/W3Cb/0Cz85BI+5KiAPE4gVbTq5ewSaEMBMkThQpVVTkp14RY9hP8W6q2Ou+x\nlQIDAQAB\n-----END PUBLIC KEY-----\n";

export const createHeader = async (payload: any = ""): Promise<string> => {
  const pubKey = await JWK.asKey(publicKey, "pem");
  const token = await JWE.createEncrypt({ format: "compact" }, pubKey)
    .update(payload)
    .final();
  const authPayload = {
    id: clientId,
    token,
  };

  return JSON.stringify(authPayload);
};
