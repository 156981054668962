import { faDiscord, faTwitch } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Apple, Google, Reddit, Twitter } from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { memo, useMemo } from "react";
import { SocialType } from "../Types/Social";

interface SocialIconProps {
  social: SocialType;
}

type MuiIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
};

const muiIcons: Partial<Record<SocialType, MuiIcon>> = {
  apple: Apple,
  google: Google,
  twitter: Twitter,
  reddit: Reddit,
};

const faIcons: Partial<Record<SocialType, JSX.Element>> = {
  discord: <FontAwesomeIcon icon={faDiscord} />,
  twitch: <FontAwesomeIcon icon={faTwitch} />,
};

const SocialIcon = memo<SocialIconProps>(({ social }) => {
  const Icon = useMemo(() => muiIcons[social], [social]);
  const faIcon = useMemo(() => faIcons[social], [social]);

  return Icon ? <Icon /> : (faIcon as any);
});

export default SocialIcon;
