import {
  CopyAll,
  Key,
  Refresh,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { memo, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import api from "../Api/api";
import ClientContext from "./ClientContext";

const PublicKey = memo(() => {
  const { current, fetch } = useContext(ClientContext);
  const { public_key, id } = current!;
  const [revealed, setRevealed] = useState<boolean>(false);
  const onCopy = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      await navigator.clipboard.writeText(public_key);
      toast("Public Key copied to clipboard", { type: "success" });
    },
    [public_key]
  );

  const onRegenerate = useCallback(async () => {
    await api.regenerateClientTokens(id);
    await fetch();
    toast("Tokens regenerated", { type: "success" });
  }, [id, fetch]);

  return (
    <Box className="container__card" sx={{ boxShadow: 2, marginTop: "30px" }}>
      <Typography component="div" variant="h4">
        <Key fontSize="medium" /> Public Key
      </Typography>
      <div className="client__keyactions">
        <Button
          onClick={onRegenerate}
          endIcon={<Refresh fontSize="large" />}
          color="error"
          variant="contained"
        >
          Regenerate
        </Button>
        <Button
          onClick={() => setRevealed(!revealed)}
          endIcon={
            revealed ? (
              <VisibilityOff fontSize="large" />
            ) : (
              <Visibility fontSize="large" />
            )
          }
          color="info"
          variant="outlined"
        >
          {revealed ? "Hide" : "Show"}
        </Button>
        <Button
          onClick={onCopy}
          endIcon={<CopyAll fontSize="large" />}
          color="info"
          variant="outlined"
        >
          Copy
        </Button>
      </div>
      {revealed && <div className="client__key">{public_key}</div>}
    </Box>
  );
});

export default PublicKey;
