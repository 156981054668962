import { Delete } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import api from "../Api/api";
import SearchBar from "../Statics/SearchBar";
import Client from "../Types/Client";
import ClientContext from "./ClientContext";
import { Link } from "react-router-dom";

const searchClients = async (search: string) => {
  return await api.searchClients(search, "");
};

const ClientOption = (data: Client) => (
  <div className="client__searchoption">{data.name}</div>
);

export default function ConnectedClients() {
  const { fetch, current } = useContext(ClientContext);

  const filter = useCallback(
    async (clients: Client[]) => {
      if (!current) return clients;

      return clients.filter(
        (client) =>
          client.id !== current.id &&
          !current.ct_allowed_clients.map((cl) => cl.id).includes(client.id)
      );
    },
    [current]
  );
  const onSelect = useCallback(
    async (client: Client) => {
      try {
        await api.addConnectedClient(current!.id, client.id);
        fetch();
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
      }
    },
    [fetch, current]
  );
  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.removeConnectedClient(current!.id, id);
        fetch();
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
      }
    },
    [fetch, current]
  );

  return (
    <Box
      className="container__card"
      sx={{ boxShadow: 2, margin: "20px 0px", marginBottom: "60px" }}
    >
      <Typography variant="h3" component="div">
        Connected Clients
      </Typography>
      {current!.ct_allowed_clients.length > 0 ? (
        current!.ct_allowed_clients.map((client) => (
          <Box className="client__connectedclient">
            <Box className="client__connectedclientinfo">
              <Link
                to={`/client/${client.id}`}
                style={{ textDecoration: "none" }}
              >
                {client.name}
              </Link>
            </Box>
            <IconButton onClick={() => onDelete(client.id)} color="error">
              <Delete />
            </IconButton>
          </Box>
        ))
      ) : (
        <Box className="client__connectedclientsempty">
          No Connected Clients
        </Box>
      )}
      <SearchBar
        search={searchClients}
        onSelect={onSelect}
        childComponent={ClientOption}
        filter={filter}
        placeholder="Search and connect a client..."
      />
    </Box>
  );
}
