import { Container } from "@mui/material";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import api from "../Api/api";
import Login from "../Login/Login";
import LoginContextProvider, {
  LoginContext,
  LoginState,
} from "../Login/LoginContext";
import Verify from "../Login/Verify";
import Register from "../Register/Register";
import { userState } from "../State/atoms";
import TeamLiquidLogo from "../TL_Logo_Dark.png";

export default function LoginPage() {
  const [state, setState] = useState<LoginState>("login");
  const [user, setUser] = useRecoilState(userState);

  const contextValue: LoginContext = useMemo(
    () => ({ current: state, set: setState }),
    [state, setState]
  );

  const innerComponent = useMemo(() => {
    switch (state) {
      case "login":
        return <Login />;
      case "register":
        return <Register />;
      case "emailverify":
        return (
          <Verify
            callback={async (verificationCode) => {
              try {
                let { code } = await api.emailVerify(verificationCode);
                await api.onLogin(code);
                const userDetails = await api.me();
                if (userDetails) setUser(userDetails);
              } catch (error) {
                const { message } = error as Error;
                toast(message, { type: "error" });
              }
            }}
            back={() => setState("login")}
          />
        );
      case "registerverify":
        return (
          <Verify
            callback={async (verificationCode) => {
              try {
                let { code } = await api.registrationVerify(verificationCode);
                await api.onLogin(code);
                const userDetails = await api.me();
                if (userDetails) setUser(userDetails);
              } catch (error) {
                const { message } = error as Error;
                toast(message, { type: "error" });
              }
            }}
            back={() => setState("login")}
          />
        );
    }
  }, [state, setUser]);

  if (user) return <div />;

  return (
    <LoginContextProvider.Provider value={contextValue}>
      <Container className="container__outercentered">
      <img src={TeamLiquidLogo} alt="" className="teamliquidlogo"></img>
        {innerComponent}
      </Container>
    </LoginContextProvider.Provider>
  );
}
