import { AddCircle, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import ClientContext from "./ClientContext";

export default function ConnectedUsers() {
  const { fetch, current } = useContext(ClientContext);
  const [email, setEmail] = useState<string>("");

  const onAdd = useCallback(
    async (email: string) => {
      try {
        const user = await api.getUserByEmail(email);
        await api.addAccessUser(current!.id, user.id);
        fetch();
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
      }
    },
    [fetch, current]
  );
  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.removeAccessUser(current!.id, id);
        fetch();
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
      }
    },
    [fetch, current]
  );
  const [onClick, loading] = useAsyncRequest((email: string) => onAdd(email));

  return (
    <Box className="container__card" sx={{ boxShadow: 2, margin: "20px 0px" }}>
      <Typography variant="h3" component="div">
        Connected Users
      </Typography>
      {current!.access_users.length > 0 ? (
        current!.access_users.map((user) => (
          <Box className="client__connectedclient">
            <Box className="client__connectedclientinfo">{user.id}</Box>
            <IconButton onClick={() => onDelete(user.id)} color="error">
              <Delete />
            </IconButton>
          </Box>
        ))
      ) : (
        <Box className="client__connectedclientsempty">No Connected Users</Box>
      )}
      <FormControl fullWidth>
        <TextField
          placeholder="Add User by Email..."
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
        <LoadingButton
          variant="contained"
          style={{
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
          loading={loading}
          onClick={onClick}
          endIcon={<AddCircle fontSize="large" />}
        >
          Add User
        </LoadingButton>
      </FormControl>
    </Box>
  );
}
