import { RemoveCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import SocialIcon from "../Statics/SocialIcon";
import Social, { SocialType } from "../Types/Social";

interface SocialFormProps {
  onChange: (social: Social) => void;
  social: Social;
  removeSocial: () => void;
}

const socialOptions: string[] = Object.keys(SocialType);

const extraFields: Partial<
  Record<SocialType, Omit<TextFieldProps, "variant">[]>
> = {
  [SocialType.Apple]: [
    {
      label: "Apple Client Key",
      type: "text",
      required: true,
      name: "client_key",
    },
    {
      label: "Apple Team ID",
      type: "text",
      required: true,
      name: "team_id",
    },
  ],
};

const SocialForm = memo<SocialFormProps>(
  ({ onChange, social, removeSocial }) => {
    const onFieldChange = useCallback(
      (e: any) => {
        const {
          target: { value, name },
        } = e;

        onChange({ ...social, [name]: value });
      },
      [social, onChange]
    );

    const addedFields: Omit<TextFieldProps, "variant">[] = useMemo(() => {
      const fields = Object(extraFields)[social.type];

      return fields ?? [];
    }, [social]);
    return (
      <Box className="client__socialcontainer" sx={{ boxShadow: 1 }}>
        <div style={{ padding: "20px" }}>
          <div className="client__socialtypes">
            {socialOptions.map((opt) => (
              <IconButton
                onClick={() => {
                  onFieldChange({
                    target: { value: Object(SocialType)[opt], name: "type" },
                  });
                }}
                className={`client__socialbutton${
                  social.type === Object(SocialType)[opt]
                    ? ` client__socialbutton--selected background-${Object(
                        SocialType
                      )[opt].toLowerCase()}`
                    : ""
                }`}
                sx={{
                  background:
                    opt === "Apple" && social.type === Object(SocialType)[opt]
                      ? "black !important"
                      : "",
                }}
                key={`socialopt-${opt}`}
              >
                <SocialIcon social={Object(SocialType)[opt]} />
              </IconButton>
            ))}
          </div>
          <FormControl fullWidth>
            <TextField
              label="Social Client ID"
              variant="outlined"
              type="text"
              className="register__input"
              required
              name="client_id"
              onChange={onFieldChange}
              value={social.client_id}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Social Client Secret"
              variant="outlined"
              type="text"
              className="register__input"
              required
              name="client_secret"
              onChange={onFieldChange}
              value={social.client_secret}
            />
          </FormControl>
          {addedFields.map((field) => (
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                className="register__input"
                {...field}
                onChange={onFieldChange}
                value={Object(social)[field.name!]}
              />
            </FormControl>
          ))}
          <Button
            endIcon={<RemoveCircle fontSize="large" />}
            variant="contained"
            color="error"
            style={{ width: "100%" }}
            onClick={removeSocial}
          >
            Remove
          </Button>
        </div>
      </Box>
    );
  }
);

export default SocialForm;
