import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import api from "../Api/api";
import { User } from "../State/atoms";

export default function UserRoleForm() {
  const [userId, setUserId] = useState<string>("");
  const [roles, setRoles] = useState<string>("");
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true);
  const timeout = useRef<any>();
  const [reqLoading, setReqLoading] = useState<boolean>(false);
  const fetchUser = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true);
        setUser(undefined);
      }
      try {
        let _user = await api.getUser(userId);
        if (_user) {
          setUser(_user);
          return;
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const _user = await api.getUserByEmail(userId);
        if (_user) {
          setUser(_user);
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(fetchUser, 200);
  }, [fetchUser]);

  const newRoles = useMemo(() => {
    return roles.split(", ").filter((role) => role.length > 0);
  }, [roles]);

  const onClick = useCallback(
    async (add: boolean) => {
      if (!user) return;
      try {
        setReqLoading(true);
        await (add
          ? api.addUserRole(user.id, newRoles)
          : api.removeUserRole(user.id, newRoles));
        fetchUser(false);
        toast(`Roles ${add ? "added" : "removed"} successfully!`, {
          type: "success",
        });
        setRoles("");
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
      } finally {
        setReqLoading(false);
      }
    },
    [newRoles, fetchUser, user]
  );

  return (
    <Box
      className="container__card"
      sx={{ boxShadow: 2, marginTop: "30px", marginBottom: "30px" }}
    >
      <Typography variant="h3" component="div">
        User Roles
      </Typography>
      <FormControl fullWidth style={{ marginTop: "30px" }}>
        <TextField
          label="User ID/Email"
          variant="outlined"
          type="text"
          className="register__input"
          required
          onChange={({ target: { value } }) => setUserId(value)}
          value={userId}
        />
      </FormControl>
      {userId.length === 0 ? (
        <></>
      ) : loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box style={{ marginTop: "30px", minHeight: "50px" }}>
          {user ? (
            <>
              <Typography
                variant="h5"
                component="div"
                style={{ marginBottom: "15px" }}
              >
                User Roles
              </Typography>
              <code>
                {user.roles.length > 0 ? user.roles.join(", ") : "No Roles"}
              </code>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 30,
              }}
            >
              Not Found
            </Box>
          )}
        </Box>
      )}
      {user && (
        <Box style={{ marginTop: "30px" }}>
          <Typography variant="h5" component="div">
            Manage Roles
          </Typography>
          <FormControl fullWidth style={{ margin: "20px 0px" }}>
            <TextField
              label="Roles"
              variant="outlined"
              type="text"
              className="register__input"
              required
              onChange={({ target: { value } }) => setRoles(value)}
              value={roles}
            />
            <FormHelperText id="component-helper-text">
              Separate roles by comma, i.e. "admin, dev"
            </FormHelperText>
          </FormControl>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridColumnGap: "10px",
              marginTop: "15px",
            }}
          >
            <LoadingButton
              variant="contained"
              color="success"
              disabled={newRoles.length === 0}
              loading={reqLoading}
              onClick={() => onClick(true)}
            >
              Add Roles
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              disabled={newRoles.length === 0}
              loading={reqLoading}
              onClick={() => onClick(false)}
            >
              Remove Roles
            </LoadingButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}
