import { toast } from "react-toastify";
import { atom } from "recoil";
import Cookies from "universal-cookie";
import api from "../Api/api";

const cookies = new Cookies();

export interface User {
  id: string;
  socials: {
    default: {
      email: string;
      verified: boolean;
    };
    twitch: {
      id: string;
      name: string;
      email: string;
    };
    twitter: {
      id: string;
      name: string;
      username: string;
    };
  };
  roles: string[];
}

export const userState = atom<User | undefined>({
  key: "user",
  default: (async (): Promise<User | undefined> => {
    const jwt = cookies.get("jwt");
    const refresh_token = cookies.get("refresh_token");

    if (jwt) {
      api.jwt = jwt;
      api.refresh = refresh_token;
      try {
        const user = await api.me();
        await api.refreshTokens();
        return user;
      } catch (error) {
        const { message } = error as Error;
        toast(message, { type: "error" });
        cookies.remove("jwt");
        cookies.remove("refresh_token");
        return;
      }
    }

    return undefined;
  })(),
});
