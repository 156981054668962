import { Box, FormControl, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface SearchBarProps<T = any> {
  search: (search: string) => Promise<T[]>;
  onSelect: (selected: T) => any;
  value?: T;
  childComponent: (data: T) => JSX.Element;
  filter?: (data: T[]) => Promise<T[]>;
  placeholder?: string;
}

const SearchBar = memo<SearchBarProps>(
  ({ search, childComponent, value, placeholder, onSelect, filter }) => {
    const [searchString, setSearchString] = useState<string>("");
    const [results, setResults] = useState<any[]>();
    const [focused, setFocused] = useState<boolean>(false);
    const timeout = useRef<any>();
    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {
          target: { value },
        } = e;
        setSearchString(value);
      },
      []
    );
    const anchorRef = React.useRef(null);

    useEffect(() => {
      const assignResults = async () => {
        const resp = await search(searchString);
        const filteredRes = filter ? await filter(resp) : resp;
        setResults(filteredRes);
      };

      if (timeout.current) clearTimeout(timeout.current);
      if (searchString.length > 0) {
        timeout.current = setTimeout(assignResults, 200);
      }
    }, [searchString, filter, search]);

    return (
      <FormControl fullWidth style={{ position: "relative" }}>
        <TextField
          style={{ margin: "20px 0px", zIndex: "2" }}
          value={searchString}
          onChange={onChange}
          placeholder={placeholder ?? "Search..."}
          ref={anchorRef}
          onBlur={() => setTimeout(() => setFocused(false), 200)}
          onFocus={() => setFocused(true)}
        />
        {results && focused && (
          <Box className="search__options" sx={{ boxShadow: 2 }}>
            {results.length > 0 ? (
              results.map((data) => (
                <div onClick={() => onSelect(data)}>{childComponent(data)}</div>
              ))
            ) : (
              <Box className="search__empty">No Results Found</Box>
            )}
          </Box>
        )}
      </FormControl>
    );
  }
);

export default SearchBar;
