import { createContext } from "react";
import { PopulatedClient } from "../Types/Client";

export interface ClientContext {
  current?: PopulatedClient;
  fetch: () => Promise<void>;
}

const clientContext: ClientContext = {
  current: undefined,
  fetch: async () => {},
};

export default createContext(clientContext);
