import React from "react";
import PageContainer from "../Statics/PageContainer";
import AuthWrapper from "../Wrappers/AuthWrapper";
import ClientForm from "./ClientForm";

export default function NewClient() {
  return (
    <AuthWrapper roles={["admin", "dev"]}>
      <PageContainer>
        <ClientForm />
      </PageContainer>
    </AuthWrapper>
  );
}
