import UserRoleForm from "../Admin/UserRoleForm";
import YourClients from "../Clients/YourClients";
import PageContainer from "../Statics/PageContainer";
import AuthWrapper from "../Wrappers/AuthWrapper";
import DeleteAccount from "../Account/DeleteAccount";

export default function Home() {
  return (
    <PageContainer>
      <AuthWrapper roles={["dev"]} render={false}>
        <YourClients />
      </AuthWrapper>
      <AuthWrapper roles={["admin"]} render={false}>
        <YourClients />
        <UserRoleForm />
      </AuthWrapper>
      <DeleteAccount />
    </PageContainer>
  );
}
