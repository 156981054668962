import { Container } from "@mui/material";
import React, { memo } from "react";
import Nav from "./Nav";

const PageContainer: React.FC = memo(({ children }) => {
  return (
    <>
      <Nav />
      <Container maxWidth="md" className="container__page">
        {children}
      </Container>
    </>
  );
});

export default PageContainer;
