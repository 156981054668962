import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import api from "../Api/api";
import useAsyncRequest from "../Api/useAsyncRequest";
import AuthWrapper from "../Wrappers/AuthWrapper";
import ClientCard from "./ClientCard";

export default function YourClients() {
  const [clients, setClients] = useState<any[]>([]);
  const fetchClients = useCallback(async () => {
    const response = await api.getClients();
    setClients(response);
  }, []);
  const [getClients, loading] = useAsyncRequest(fetchClients);

  useEffect(() => {
    getClients();
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="container__card" sx={{ boxShadow: 2 }}>
      <Typography variant="h3" component="div" className="container__header">
        Clients
      </Typography>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {clients.map((client) => (
            <ClientCard
              key={`client-${client.id}`}
              refresh={fetchClients}
              {...client}
            />
          ))}
          <AuthWrapper roles={["admin", "dev"]} render={false}>
            <NavLink to="/client/new" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                className="client__card client__card--new"
                sx={{ boxShadow: 1, borderRadius: 1, background: "success" }}
              >
                <Add fontSize="large" /> New Client
              </Button>
            </NavLink>
          </AuthWrapper>
        </>
      )}
    </Box>
  );
}
